// require("company_custom/pigeonhole")
// require("company_custom/webcast_QA_01")

document.addEventListener("turbolinks:load", () => {
	function show_consent_form() {
		var event_slug = window.location.pathname.split('/')[1];
    if($("#booth-attr").data('consent') != true && document.cookie.indexOf(event_slug) == -1 ){
    	if (document.getElementById("consent")){
    		 document.getElementById("consent").addEventListener("click", consent_form);  
    	}
      $("#consentModal").modal('show');      
    }else{
      $("#consentFinishModal").modal('show');
    }
  }

  function consent_form(){  	
    var event_slug = window.location.pathname.split('/')[1];
    createCookie(event_slug, "shown_consent_form", 1);
    $.ajax({
      url: '/downloads/consent_request',
      type: "POST",
      data: { event_slug, status: true },
      success: function () { $("#consentModal").modal('hide'); }
    });
  }

  function createCookie(name, value, days) {
    var date, expires;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      date.setHours(0);
      date.setMinutes(0);
      expires = "; expires=" + date.toUTCString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }  

  function changeTimezone(date, ianatz = "Asia/Singapore") {
    var invdate = new Date(date.toLocaleString('en-US', {timeZone: ianatz,hour12: false}));
    var diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff); 
  }
  
  if (window.location.pathname.indexOf("/webcast") >= 0) {    
    if(document.querySelector("#booth-attr")){
      if ($("#booth-attr").data('request_consent') == true && $(".lucky-draw-link").length > 0 ){
        $(".lucky-draw-link").on('click',function(e){
          e.preventDefault()
          show_consent_form()
        })

        //JS to refresh page at lucky draw start time
        var ruby_to_js_live_datetime_luckydraw = changeTimezone(new Date(ruby_current_full_datetime));
        var luckydraw_start_datetime = document.querySelector("#booth-attr").dataset.luckyDrawStarttime;
        luckydraw_start_datetime = changeTimezone(new Date(luckydraw_start_datetime));  
        var luckydraw_countdown = setInterval(function() {
          if ( new Date(ruby_to_js_live_datetime_luckydraw).getTime() == new Date(luckydraw_start_datetime).getTime() ) {
            console.log("refresh!")
            clearInterval(luckydraw_countdown)
            window.location.reload();
          }
          else if (ruby_to_js_live_datetime_luckydraw <= luckydraw_start_datetime ) {
            console.log("lucky draw start soon")
          }
          else{
            console.log("lucky draw ended")
            clearInterval(luckydraw_countdown)
          }

          ruby_to_js_live_datetime_luckydraw.setSeconds(ruby_to_js_live_datetime_luckydraw.getSeconds() + 1);    
        }, 1000);
      }

      //JS to remove livechat modal once livechat time has started 
      if( $("#booth-attr").data('livechat-starttime') && $(".livechat-link").length > 0 ){                
        var ruby_to_js_live_datetime_livechat = changeTimezone(new Date(ruby_current_full_datetime));
        var livechat_start_datetime = document.querySelector("#booth-attr").dataset.livechatStarttime;
        var livechat_end_datetime = document.querySelector("#booth-attr").dataset.livechatEndtime;
        livechat_start_datetime = changeTimezone(new Date(livechat_start_datetime)); 
        livechat_end_datetime = changeTimezone(new Date(livechat_end_datetime)); 

        var livechat_countdown = setInterval(function() {        
          if (ruby_to_js_live_datetime_livechat <= livechat_start_datetime ) {
            console.log("live chat start soon")
          }
          else if (ruby_to_js_live_datetime_livechat >= livechat_start_datetime && ruby_to_js_live_datetime_livechat <= livechat_end_datetime ) {
            console.log("live chat started")
            $(".livechat-link").removeAttr("data-toggle")
            $(".livechat-link").removeAttr("data-target")
          }
          else{
            clearInterval(livechat_countdown)
            console.log("live chat ended")
            $(".livechat-link").attr("data-toggle", "modal")
            $(".livechat-link").attr("data-target", "#livechatModal")
          }

          ruby_to_js_live_datetime_livechat.setSeconds(ruby_to_js_live_datetime_livechat.getSeconds() + 1);    
        }, 1000);
      }      
    }
  }
})